<template>
  <Title :text="t(`t1`)" />
  <div class="grid-column grid-column_span_5">
    <Promo
      class="promo_full_width"
      :src-pic="require(`@/assets/cover/innovation.jpg`)"
      :src-pic-mobile="require(`@/assets/cover/m/innovation.jpg`)"
    />
  </div>

  <div class="grid-column">
    <Share :title="t(`t1`)" />
  </div>
  <div class="grid-column grid-column_span_4 grid-column_row_2">
    <div class="h3" v-html="t(`t2`)"></div>
  </div>

  <div v-if="isMobile" class="grid-line"></div>
  <div class="grid-column grid-column_span_row_2"></div>
  <div class="grid-column grid-column_span_2">
    <Preview :image="require('@/assets/innovation/1.jpg')">
      <div class="preview-grid preview-grid_direction_row">
        <div class="h3" v-html="t(`t3`)"></div>
        <div v-html="t(`t4`)"></div>
      </div>
    </Preview>
  </div>
  <div v-if="isMobile" class="grid-line"></div>
  <div class="grid-column grid-column_span_2">
    <Preview :image="require('@/assets/innovation/2.jpg')">
      <div class="preview-grid preview-grid_direction_row">
        <div class="h3" v-html="t(`t5`)"></div>
        <div v-html="t(`t6`)"></div>
      </div>
    </Preview>
  </div>
  <div v-if="isMobile" class="grid-line"></div>
  <div class="grid-column grid-column_span_2">
    <Preview :image="require('@/assets/innovation/3.jpg')">
      <div class="preview-grid preview-grid_direction_row">
        <div class="h3" v-html="t(`t7`)"></div>
        <div v-html="t(`t8`)"></div>
      </div>
    </Preview>
  </div>
  <div class="grid-column grid-column_span_2"></div>

  <div class="grid-column"></div>
  <div class="grid-column"></div>
  <div class="grid-column"></div>
  <div class="grid-column"></div>
  <div class="grid-column"></div>

  <div v-if="isMobile" class="grid-line"></div>

  <News />
</template>

<script>
import { defineComponent } from "vue";
import { useI18n } from "vue-i18n";

import Title from "@/components/Title.vue";
import Promo from "@/components/Promo.vue";
import Share from "@/components/Share.vue";
import News from "@/components/News.vue";
import Preview from "@/components/Preview.vue";

export default defineComponent({
  name: "Innovation",
  components: {
    Title,
    Promo,
    Share,
    News,
    Preview,
  },
  setup() {
    const { t } = useI18n({
      inheritLocale: true,
      useScope: "local",
    });

    return { t };
  },
});
</script>

<style lang="scss" scoped></style>

<i18n>
{
  "en": {
    "t1": "Innovations",
    "t2": "<p>The Office of Knowledge and Innovation develops and deepens cooperation between Qatar and Russia in the field of innovations and technologies, contributing the achievement of sustainable development goals and economic prosperity.</p><p>The Office is:</p><ul><li>The main contact for acquainting Qatari and Russian companies with technological focus and investment potential of Qatar and Russia.</li><li>A rocket for high-tech companies taking the first steps to enter the Qatar and Russian markets.</li><li>Establishing business contacts and beneficial cooperation for developers of innovative technologies.</li><li>A business consultant for the adoption of Qatari and Russian products to Qatari and Russian markets.</li><li>Providing mentoring support for startups: successful pitch session and business missions.</li><li>Helping to gain international expertise in the innovative ecosystem of Qatar and Russia.</li></ul>",
    "t3": "INNOVATION ECOSYSTEM",
    "t4": "<ul><li>&nbsp;Innovation Ecosystem of Qatar.</li><li>&nbsp;Innovation Ecosystem of Russia.<br/><br/></li><li>&nbsp;The Office:<ul><li>Establishes connections between main innovation and technology ecosystem stakeholders from Russia and Qatar.</li><li>Unites relevant companies.</li><li>Opens business opportunities and expands cooperation in the field of high-technology and innovation.</li></ul></li></ul>",
    "t5": "SERVICES FOR STARTUPS AND SME",
    "t6": "<ul><li>Acquainting Innovation Ecosystem of Qatar and Russia through soft-landing programs.</li><li>Establish contacts with business and Institutions for Development.</li><li>Gain international expertise of project from leading business experts and structured go-to-market plan.</li><li>Acceleration of business in Qatar and Russia for companies aspiring to work on each other’s markets.</li><li>Be informed and participate in industry demo days, pitch sessions, business missions and acceleration programs.</li><li>Advise on the possibilities of attracting financing and investments.</li></ul>",
    "t7": "SERVICES FOR INDUSTRIAL COMPANIES",
    "t8": "<ul><li>Acceleration of business in Qatar and Russia for companies aspiring to work on each other’s markets.</li><li>Call for innovation: scouting startups according to technology direction and further acceleration.</li><li>Organization of industry events: Demo days, webinars, panel discussions.</li><li>Organization of business-missions for Qatari and Russian businessmen to both countries with well prepared meeting schedule.</li><li>Creating opportunities for joint R&amp;D centers and joint technologies.</li></ul>",
  },
  "ru": {
    "t1": "Инновации",
    "t2": "<p>Office of Knowledge and Innovation (OKI) обеспечивает взаимодействие между Катаром и Россией в области инноваций и технологий, способствуя достижению целей устойчивого развития и экономического процветания.</p><ul><li>OKI — это главный консультант для катарских и российских компаний технологической направленности, интересующихся инвестиционным потенциалом Катара и России.</li><li>Точка входа на рынки Катара и России для высокотехнологичных компаний, а также бизнес-консультант по внедрению катарских и российских продуктов на эти рынки.</li><li>Благодаря контактам на самом высоком уровне в России и в Катаре, OKI способствует выгодному сотрудничеству для разработчиков инновационных технологий.</li><li>OKI оказывает менторскую поддержку стартапов (успешные питч-сессии и бизнес-миссии), а также помощь в получении международного опыта в инновационной экосистеме Катара и России.</li></ul>",
    "t3": "ИННОВАЦИОННАЯ СРЕДА",
    "t4": "<ul><li>Инновационная среда Катара.</li><li>Инновационная среда России.<br/><br/></li><li>OKI:<ul><li>устанавливает связи между основными участниками инновационной и технологической сферы из России и Катара.</li><li>соединяет профильные компании.</li><li>открывает возможности для бизнеса и расширяет сотрудничество в сфере hi-tech и инноваций.</li></ul></li></ul>",
    "t5": "УСЛУГИ ДЛЯ СТАРТАПОВ И МАЛОГО И СРЕДНЕГО БИЗНЕСА",
    "t6": "<ul><li>Знакомство с инновационной средой Катара и России через программы «мягкой посадки».</li><li>Установление контактов с бизнесом и государственными институциями.</li><li>Международная экспертиза от ведущих бизнес-экспертов и разработка структурированного плана вывода на рынок.</li><li>Развитие бизнеса в Катаре и России для компаний, стремящихся работать на рынках друг друга.</li><li>Отраслевые демонстрационные дни, питч-сессии, бизнес-миссии и программы акселерации.</li><li>Консультирование по вопросам привлечения финансирования и инвестиций.</li></ul>",
    "t7": "УСЛУГИ ДЛЯ ИНДУСТРИАЛЬНЫХ КОМПАНИЙ",
    "t8": "<ul><li>Развитие бизнеса в Катаре и России для компаний, стремящихся работать на рынках друг друга.</li><li>Призыв к инновациям: поиск стартапов в соответствии с технологическим направлением и дальнейшее развитие сотрудничества.</li><li>Организация отраслевых мероприятий: демонстрационные дни, вебинары, панельные дискуссии.</li><li>Организация бизнес-миссий катарских и российских бизнесменов в обе страны с проработанным графиком встреч.</li><li>Создание возможностей для совместных центров НИОКР и совместных технологий.</li></ul>",
  },
  "ar": {
    "t1": "الابتكار",
    "t2": "<p>يعمل مكتب المعرفة والابتكار على تطوير وتعميق التعاون بين دولة قطر وروسيا في مجال الابتكارات والتكنولوجيات، والمساهمة في تحقيق أهداف التنمية المستدامة والازدهار الاقتصادي.</p><p>المكتب هو:</p><ul><li>جهة الاتصال الرئيسية لتعريف الشركات القطرية والروسية بالتركيز التكنولوجي والإمكانيات الاستثمارية لقطر وروسيا.</li><li>منصة الانطلاق لشركات التكنولوجيا العالية التي تخطو أولى خطواتها لدخول الأسواق القطرية والروسية.</li><li>إقامة الاتصالات التجارية والتعاون المفيد لمطوري التكنولوجيات المبتكرة.</li><li>مستشار في قطاع الأعمال لتبني المنتجات القطرية والروسية للأسواق القطرية والروسية.</li><li>مقدم الدعم الإرشادي للشركات الناشئة: جلسة عرض تقديمية ناجحة ومهام تجارية.</li><li>المساعد في اكتساب الخبرة الدولية في النظام البيئي المبتكر لقطر وروسيا.</li></ul>",
    "t3": "بيئة مبتكرة",
    "t4": "<ul><li>التعرف على البيئة المبتكرة لقطر وروسيا من خلال برامج 'النزول الميسر'</li><li>إقامة اتصالات مع مؤسسات الأعمال والمؤسسات الحكومية</li><li>الخبرة الدولية من خبراء الأعمال البارزين ووضع خطة منظمة للدخول إلى السوق</li><li>تطوير الأعمال في قطر وروسيا للشركات الراغبة في العمل في أسواق بعضها البعض</li><li>أيام عرض الصناعة ، وجلسات العروض التقديمية ، ومهام الأعمال ، وبرامج التسريع</li><li>الاستشارات الخاصة بجذب التمويل والاستثمارات</li></ul>",
    "t5": "خدمات للشركات الناشئة والشركات الصغيرة والمتوسطة",
    "t6": "<ul><li>التعرف على البيئة المبتكرة لقطر وروسيا من خلال برامج 'النزول الميسر'.</li><li>إقامة اتصالات مع مؤسسات الأعمال والمؤسسات الحكومية.</li><li>الخبرة الدولية من خبراء الأعمال البارزين ووضع خطة منظمة للدخول إلى السوق.</li><li>تطوير الأعمال في قطر وروسيا للشركات الراغبة في العمل في أسواق بعضها البعض.</li><li>أيام عرض الصناعة ، وجلسات العروض التقديمية ، ومهام الأعمال ، وبرامج التسريع.</li><li>الاستشارات الخاصة بجذب التمويل والاستثمارات.</li></ul>",
    "t7": "خدمات للشركات الصناعية",
    "t8": "<ul><li>تطوير الأعمال في قطر وروسيا للشركات الراغبة في العمل في أسواق بعضها البعض</li><li>دعوة للابتكار: البحث عن الشركات الناشئة وفقًا للاتجاه التكنولوجي وزيادة تطوير التعاون</li><li>تنظيم الفعاليات في مجال الصناعة: أيام تجريبية وندوات عبر الإنترنت وحلقات نقاش</li><li>تنظيم بعثات تجارية لرجال الأعمال القطريين والروس إلى كلا البلدين مع جدول اجتماعات متطور</li><li>خلق فرص لمراكز البحث والتطوير المشتركة والتكنولوجيا</li></ul>",
  }
}
</i18n>
